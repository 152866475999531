
export function prepData(courses, cart, noted) {
   courses = courses.filter(c => c && c.title && c.course_quality)
        for (let i = 0; i < courses.length; i++) {
          const [dept, number] = courses[i].id.split('-')
          courses[i].dept = dept
          courses[i].number = number
          courses[i].added = false
        }

        for (let i = 0; i < courses.length; i++) {
          const course = courses[i]
          if (courses[i]) {
            for (let j = 0; j < cart.length; j++) {
              if (cart[j].dept === course.dept && cart[j].number === course.number) { courses[i].added = true }
            }
            for (let j = 0; j < noted.length; j++) {
              if (noted[j].dept === course.dept && noted[j].number === course.number) { courses[i].noted = true }
            }
            courses[i].note = "";
          }
        }
        return courses
}
